<template>
  <div class="object-card" :class="{ 'expand': mode }" v-if="activeItem">
    <div class="object-card__drag" v-if="mode"></div>
    <img alt="карточка свернута" src="@/assets/images/svg/up.svg" class="object-card__drag object-card__drag_up" v-else>
    <a class="prev-link" @touchstart="setPosition($event)">
      <picture>
        <source srcset="../assets/images/svg/prev.svg" media="(min-width: 769px)">
        <source srcset="../assets/images/svg/prev_white.svg" media="(max-width: 768px)">
        <img src="@/assets/images/svg/prev.svg" alt="" class="prev-link__icon" @click="returnToResults">
      </picture>

      <span v-if="mode" @click="returnToResults">Назад</span>
      <span v-else @click="returnToResults"></span>
      <img src="@/assets/images/svg/close_white.svg" role="button" alt="закрыть" class="object-card__close"
        @click="closeCard">
    </a>
    <div class="object-card__main" v-if="mode">
      <div class="object-card__top">
        <h3 class="object-card__title">{{ activeItem.name }}</h3>
        <div class="object-card__share">
          <el-tooltip class="item" effect="dark" content="Ссылка скопирована" placement="bottom-end" title="Скопировать"
            :manual="true" :value="tooltip">
            <img src="@/assets/images/svg/share.svg" alt="" @click="copyLink">
          </el-tooltip>
        </div>
        <a v-if="getLinksCertificates.length === 1" :href="getLinksCertificates[0].link"
          target="_blank" rel="noopener noreferrer" class="object-caption">
            Просмотреть сертификат
        </a>
        <a v-else-if="getLinksCertificates.length > 1" v-for="item in getLinksCertificates" :key="item.key" :href="item.link"
          target="_blank" rel="noopener noreferrer" class="object-caption">
            Просмотреть сертификат {{ item.key }}
        </a>
      </div>
      <div class="object-contacts" v-if="isAuth && activeItem.inn">
        <p class="object-contacts__text object-contacts__text--small">
          <b>ИНН: </b>{{ activeItem.inn }}
        </p>
      </div>
      <div class="object-contacts">
        <img src="@/assets/images/svg/phone.svg" alt="" class="object-contacts__icon"
          v-if="activeItem.phone_1 || activeItem.phone_2">
        <p class="object-contacts__text" v-if="activeItem.phone_1">
          <a :href="`tel:${activeItem.phone_1}`" class="object-contacts__text object-contacts__text--pink">
            {{ this.formatPhone(activeItem.phone_1) }}
          </a>
          <!--          <span class="object-caption">Показать номер</span>-->
        </p>
        <p class="object-contacts__text" v-if="activeItem.phone_2">
          <a :href="`tel:${activeItem.phone_2}`" class="object-contacts__text object-contacts__text--pink">
            {{ this.formatPhone(activeItem.phone_2) }}
          </a>
          <!--          <span class="object-caption">Показать номер</span>-->
        </p>
      </div>
      <div class="object-contacts" v-if="activeItem.address">
        <img src="@/assets/images/svg/location.svg" alt="" class="object-contacts__icon">
        <p class="object-contacts__text">{{ activeItem.address }}</p>
      </div>
      <div class="object-contacts" v-if="activeItem.web_site">
        <img src="@/assets/images/svg/globus.svg" alt="" class="object-contacts__icon">
        <a :href="activeItem.web_site" target="_blank" rel="noopener noreferrer"
          class="object-contacts__text object-contacts__text--pink">
          {{ activeItem.web_site }}
        </a>
      </div>
      <div class="object-contacts" v-if="activeItem.email_1">
        <img src="@/assets/images/svg/mail.svg" alt="" class="object-contacts__icon">
        <a :href="`mailto:${activeItem.email_1}`" class="object-contacts__text object-contacts__text--pink">
          {{ activeItem.email_1 }}
        </a>
      </div>
      <div class="object-contacts" v-if="activeItem.email_2">
        <img src="@/assets/images/svg/mail.svg" alt="" class="object-contacts__icon">
        <a :href="`mailto:${activeItem.email_2}`" class="object-contacts__text object-contacts__text--pink">
          {{ activeItem.email_2 }}
        </a>
      </div>
      <div class="object-description" v-for="group in productGroups" :key="group.id">
        <p class="object-title">{{ group.name }}</p>
        <p class="object-title__small object-title__no-mb" v-for="(product, index) in group.products" :key="product.id">
          <span v-if="group.expand || index < 4">{{ product.name }}</span>
        </p>
        <p class="filters__btn" v-if="!group.expand" @click="group.expand = true">
          Показать все
          <img src="@/assets/images/svg/chevron-right.svg" alt="">
        </p>
      </div>
      <div class="object-description" v-if="activeItem.attribute || activeItem.attributes_ids.length">
        <p class="object-title">Признак партнера</p>
        <p class="object-title__small" v-for='attribute in getAttributeNames' :key="attribute.id">{{ attribute.name }}
        </p>
      </div>
      <div class="object-description" v-if="activeItem.description">
        <p class="object-title">Описание</p>
        <p class="object-title__small">{{ activeItem.description }}</p>
      </div>
      <ul class="socials">
        <li v-for="social in activeItem.social_networks" :key="social.link" class="socials__item">
          <a :href="social.link" class="socials__link" target="_blank" rel="noopener noreferrer">
            <img class="socials__icon" :src="getSocialIcon(social.id)" alt="">
          </a>
        </li>
      </ul>
    </div>
  </div>

</template>

<script>
export default {
  name: 'PartnerCard',
  data() {
    return {
      mode: 1,
      startPosition: null,
      tooltip: false,
      productGroups: [],
      phoneMask: null
    }
  },
  watch: {
    activeItem: {
      deep: true,
      handler(newItem, oldItem) {
        if (newItem && oldItem && newItem.id !== oldItem.id) {
          // this.$store.dispatch('addVisited', oldItem.id);
          this.$store.dispatch('setCurrentLocation', { coords: this.activeItem.coords, zoom: 15 });
          this.initGroups();
        }
      }
    }
  },
  mounted() {
    if ('ontouchstart' in document.documentElement || navigator.maxTouchPoints > 0) {
      document.addEventListener('touchend', e => {
        this.updateMode(e.changedTouches[0].clientY);
      });
    }
    if (this.activeItem) {
      this.$store.dispatch('setCurrentLocation', { coords: this.activeItem.coords, zoom: 15 });
      this.initGroups();
      this.getMaskData();
    } else {
      const interval = setInterval(() => {
        if (this.activeItem) {
          this.$store.dispatch('setCurrentLocation', { coords: this.activeItem.coords, zoom: 15 });
          this.initGroups();
          this.getMaskData();
          clearInterval(interval);
        }
      }, 500);
    }
  },
  beforeDestroy() {
    this.$store.dispatch('addVisited', this.activeItem.id);
    this.$store.dispatch('setActiveItem', null);
  },
  methods: {
    setPosition(event) {
      this.startPosition = event.touches[0].clientY;
    },
    updateMode(finishPosition) {
      if (!this.startPosition) { return; }
      if (finishPosition - this.startPosition > 50) {
        this.mode = this.mode === 0 ? 0 : this.mode - 1;
      } else if ((finishPosition - this.startPosition < -50)) {
        this.mode = this.mode === 2 ? 0 : this.mode + 1;
      }
      this.startPosition = null;
    },
    copyLink() {
      this.tooltip = true;
      const link = window.location.href;
      const input = document.createElement('input');
      input.value = link;
      document.body.appendChild(input);
      input.select();
      document.execCommand('copy');
      document.body.removeChild(input);
      setTimeout(() => { this.tooltip = false }, 1000);
    },
    returnToResults() {
      if (this.$store.getters.getSearchResults && this.$store.getters.getSearchResults.length) {
        this.$router.go(-1);
      } else {
        this.$router.push('/partners');
      }
    },
    closeCard() {
      this.$router.push('/partners');
    },
    getSocialIcon(id) {
      const network = this.socials && this.socials.find(social => social.id === id);
      if (network) {
        return network.image ? network.image.thumbnail_link : network.image_link;
      }
      return '';
    },
    initGroups() {
      const groups = [];
      if (this.activeItem.product_ids && this.activeItem.product_ids.length) {
        const allGroups = this.$store.getters['getGroups'];
        allGroups.forEach(group => {
          const isIncluded = group.products.some(product => this.activeItem.product_ids.includes(product.id));
          if (isIncluded) {
            const list = group.products.filter(product => this.activeItem.product_ids.includes(product.id))
            groups.push({
              id: group.id,
              name: group.name,
              products: list,
              expand: list.length < 5
            });
          }
        });
      }
      this.productGroups = groups;
    },
    getMaskData() {
      const businesRegionId = this.activeItem.business_regions_ids[0];
      const businesRegion = this.$store.getters['getBusinessRegions'].find(obj => obj.id === businesRegionId);
      const geoRegionId = businesRegion.geo_regions[0];
      const geoRegion = this.$store.getters['getGeoRegions'].find(obj => obj.id === geoRegionId);
      this.phoneMask = this.$store.getters['getPhoneMasks'].find(obj => obj.country === geoRegion.country_name);
    },
    formatPhone(phone) {
      return this.phoneMask ? phone.replace(new RegExp(this.phoneMask.regexp), this.phoneMask.substr) : '';
    }
  },
  computed: {
    isAuth() {
      return this.$store.getters['isAuth'];
    },
    /** Получить ссылки на сертификаты */
    getLinksCertificates() {
      const links = [];

      /** Если есть ссылки на сертификаты старого образца */
      try {
        if (this.activeItem.sertificate_links.length) {
          links.push({
            key: '',
            link: this.activeItem.sertificate_links[0]
          });
        }
      } catch (error) {
        console.log('Ошибка при обработке данных сертификатов старого образца:', error.message);
      }

      /** Если есть ссылки на сертификаты нового образца */
      try {
        if (this.activeItem.certifications.length) {
          for (let index = 0; index < this.activeItem.certifications.length; index++) {
            links.push({
              key: this.activeItem.certifications[index].key,
              link: this.activeItem.certifications[index].link
            });
          }
        }
      } catch (error) {
        console.log('Ошибка при обработке данных сертификатов нового образца:', error.message);
      }

      /** Если пользователь синхронизирован с центром сертификации */
      try {
        if (this.activeItem.certificateCA) {
          Object.keys(this.activeItem.certificateCA).forEach((key) => {
            const validCertificates = this.activeItem.certificateCA[key].certificates.filter(obj => obj.valid === true);
            if (validCertificates.length) {
              links.push({
                key,
                link: validCertificates[0].shortLink
              });
            }
          });
        }
      } catch (error) {
        console.log('Ошибка при обработке данных синхронизированых сертификатов:', error.message);
      }

      return links;
    },
    activeItem() {
      let itemId = this.$store.getters['getActiveItem'];
      if (!itemId) {
        itemId = this.$route.params.id;
        this.$store.dispatch('setActiveItem', itemId);
      }
      return this.$store.getters['getPartners'].find(obj => obj.id === itemId);
    },
    socials() {
      return this.$store.getters['getSocials'];
    },
    attributes() {
      return this.$store.getters['getPartnerAttributes'];
    },
    attributeName() {
      if (this.activeItem && this.attributes && this.attributes.length) {
        const attrObject = this.attributes.find(attr => attr.id === this.activeItem.attribute);
        return attrObject ? attrObject.name : '';
      }
      return '';
    },
    getAttributeNames() {
      if (this.activeItem && this.attributes && this.attributes.length) {
        const attributeId = [...this.activeItem.attributes_ids];
        const attributeNames = attributeId.map(id => this.attributes.find(item => item.id === id)).sort((a, b) => (a.name > b.name ? 1 : -1));
        return attributeNames;
      }
      return [];
    }
  }
}
</script>

<style scoped>
.prev-link {
  display: flex;
  align-items: center;
  font-size: 18px;
  line-height: 24px;
  color: #000000;
  padding-left: 15px;
  height: 42px;
  background: #F2F2F2;
  border-top: 1px solid #BFBFBF;
  border-bottom: 1px solid #BFBFBF;
  position: sticky;
  top: 0;
}

.prev-link__icon {
  width: 20px;
  height: 13px;
  margin-right: 15px;
  cursor: pointer;
}

.prev-link span {
  cursor: pointer;
}

.object-card__edit {
  margin-left: auto;
}

.object-card__main {
  padding: 0 15px 20px;
  overflow-y: auto;
  height: calc(100vh - 250px);
}

.object-card__main::-webkit-scrollbar {
  width: 9px;
}

.object-card__main::-webkit-scrollbar-thumb {
  background-color: black;
  border-right: 4px #fff solid;
  background-clip: padding-box;
}

.object-card__pic {
  width: 100%;
  object-fit: cover;
  margin-bottom: 20px;
}

.object-card__top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: 20px;
  margin-top: 20px;
}

.object-card__title {
  max-width: 90%;
  overflow-wrap: anywhere;
  font-weight: bold;
  font-size: 22px;
  line-height: 24px;
  color: #000000;
}

.object-card__close {
  margin-left: auto;
  width: 26px;
  height: 26px;
}

@media (min-width: 768px) {
  .object-card__close {
    display: none;
  }
}

.object-title__small {
  font-weight: normal;
  font-size: 18px;
  line-height: 24px;
  color: #000000;
  margin-bottom: 10px;
}

.object-title__no-mb:not(:last-child) {
  margin-bottom: 0;
}

.filters__btn {
  font-size: 18px;
  line-height: 24px;
  color: #DC143C;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.filters__btn img {
  width: 8px;
  height: 12px;
  margin-left: 8px;
}

.object-card__share {
  width: 25px;
  height: 23px;
  cursor: pointer;
}

.object-contacts {
  display: flex;
  margin-bottom: 20px;
}

.object-contacts__icon {
  width: 20px;
  height: 20px;
  margin-right: 12px;
}

.object-contacts__text {
  font-size: 18px;
  line-height: 24px;
  color: #000000;
}

.object-contacts__text:not(:last-child) {
  margin-right: 20px;
}

.object-contacts__text--small {
  font-size: 16px;
  line-height: 22px;
}

.object-contacts__text--pink {
  color: #DC143C;
}

.object-caption {
  display: block;
  width: 100%;
  font-size: 14px;
  line-height: 17px;
  color: #DC143C;
  cursor: pointer;
  margin-top: 5px;
}

.object-description {
  margin-bottom: 10px;
}

.object-title {
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  color: #000000;
  text-transform: uppercase;
  margin-bottom: 10px;
  margin-top: 20px;
}

.object-title__small {
  font-weight: normal;
  font-size: 18px;
  line-height: 24px;
  color: #000000;
  margin-bottom: 10px;
}

.socials {
  display: flex;
  align-items: center;
}

.socials__item {
  flex-shrink: 0;
}

.socials__item:not(:last-child) {
  margin-right: 10px;
}

.socials__icon {
  height: 25px;
  width: 25px;
  object-fit: contain;
  object-position: center;
  margin-right: 7px;
}

.object-card__drag {
  position: absolute;
  top: 6px;
  left: 50%;
  transform: translateX(-50%);
  width: 18px;
  height: 3px;
  background-color: rgba(255, 255, 255, .7);
  display: none;
}

.object-card__drag_up {
  width: 22px;
  height: 6px;
  background-color: transparent;
}

@media (max-width: 1023px) {
  .object-card {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100vw;
    background-color: #fff;
    height: 56px;
    transition: height .2s ease-out;
  }

  .object-card.expand {
    top: 189px;
    height: calc(100vh - 189px);
  }

  .prev-link {
    margin: 0;
    padding: 15px;
    background-color: #000;
    color: #fff;
    min-height: 56px;
  }

  .object-card__drag {
    display: block;
  }
}
</style>
